<template>
	<MeetingsList></MeetingsList>
</template>

<script>
import MeetingsList from "@/components/shared/tools/meetingSummary/List.vue";

export default {
	name: "MeetingSummaryPage",

	components: {
		MeetingsList,
	},
};
</script>
